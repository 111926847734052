<template>

  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      text="Carregando Pendências"
    >
      <img
        src="@/assets/images/logo/logo-loading.gif"
        width="40px"
      >
    </vue-element-loading>

    <!-- <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :plan-options="planOptions"
      @refetch-data="refetchData"
    /> -->

    <!-- Filters -->
    <!-- <users-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <!-- <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Pesquisar"
              />
              <!-- <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="aprovacoes"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Sem resultados"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'hit-editar', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(nomeComposicao)="data">
          <b-media vertical-align="center">
            <b-link
              :to="{ name: 'hit-editar', params: { id: data.item.id } }"
              class="d-block text-nowrap text-dark"
            >
              {{ data.item.nomeComposicao }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(statusAprovacaoHit)="data">
          <b-badge
            pill
            :variant="`light-${statusAprovacaoVariant(data.item.statusAprovacaoHit)}`"
            class="text-capitalize"
          >
            {{ statusAprovacaoLabel(data.item.statusAprovacaoHit) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            class="text-right"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            
            <b-dropdown-item :to="{ name: 'hit-editar', params: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Corrigir</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalAprovacoes"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="mudarPagina"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import VueElementLoading from 'vue-element-loading'
import useJwt from '@/auth/jwt/useJwt'
import UsersListFilters from './UsersListFilters.vue'

// CARREGANDO DE PÁGINA PARA API

export default {
  components: {
    UsersListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
    VueElementLoading,
  },
  data() {
    return {

      aprovacoes: [],
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'nomeComposicao', label: 'Composição', sortable: true },
        { key: 'statusAprovacaoHit', label: 'Status', sortable: true },
        { key: 'actions', label: '' },
      ],
      perPage: 25,
      currentPage: 1,
      totalAprovacoes: 0,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      refUserListTable: null,
      carregamentoApi: false,

    }
  },
  created() {
    this.hitCompositorPendencias()
  },
  methods: {

    // STATUS

    statusAprovacaoVariant(status) {
      if (status === 'EM_ANALISE') return 'warning'
      if (status === 'APROVADO') return 'success'
      if (status === 'REPROVADO_GERAL') return 'danger'
      if (status === 'REPROVADO_INFORMACOES') return 'danger'
      if (status === 'REPROVADO_GUIA') return 'danger'
      if (status === 'PENDENTE_PAGAMENTO') return 'danger'
      if (status === 'AGUARDANDO_GUIA') return 'warning'
      return 'warning'
    },

    statusAprovacaoLabel(status) {
      if (status === 'EM_ANALISE') return 'Em análise'
      if (status === 'APROVADO') return 'Aprovado'
      if (status === 'REPROVADO_GERAL') return 'Reprovado'
      if (status === 'REPROVADO_INFORMACOES') return 'Informações Reprovadas'
      if (status === 'REPROVADO_GUIA') return 'Guia Reprovada'
      if (status === 'PENDENTE_PAGAMENTO') return 'Pendente Pagamento'
      if (status === 'AGUARDANDO_GUIA') return 'Aguardando Guia'
      return 'Em análise'
    },

    // STATUS

    // CARREGAR PENDÊNCIAS

    hitCompositorPendencias() {
      this.carregamentoApi = true

      const paginaAtual = this.currentPage - 1 // DIMINUIR -1 DA PÁGINA ATUAL

      // console.log( paginaAtual );

      useJwt.hitCompositorPendencias({
        page: paginaAtual,
        size: this.perPage,
        sort: 'DESC',
      })
        .then(response => {

          this.aprovacoes = response.data.content
          this.totalAprovacoes = response.data.totalElements
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    // CARREGAR APROVAÇÕES

    // MUDAR PÁGINA

    mudarPagina() {
      this.hitCompositorPendencias()
    },

    // MUDAR PÁGINA

  },

}

</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
